import useSiteName from '@mediashop/app/hooks/useSiteName';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import { AddressField } from '@mediashop/app/api/types/AddressConfiguration';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import DynamicTextarea from '../../../atom/DynamicTextarea';
import LoqateInput from '../../../atom/LoqateInput';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '../../../atom/Checkbox';
import Input from '../../../atom/Input';
import { FormikProps } from 'formik';
import MaskedInput from '../../../atom/MaskedInput';
import RadioButtonGroup from '../../../atom/RadioButtonGroup';
import DynamicSelect from '../../DynamicAddressForm/DynamicSelect';
import DynamicDateOfBirth from '../../DynamicAddressForm/DynamicDateOfBirth';
import PhoneNumberInput from '../../../atom/PhoneNumberInput';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import PasswordWithShowInput from '../../../atom/PasswordInput';
import useCustomerDataFromSession from '@mediashop/app/hooks/useCustomerDataFromSession';

const componentName = 'dynamic-input';

type DynamicFormikFieldProps = BaseProps &
    AddressField & {
        formik: FormikProps<Record<string, string>>;
        defaultValue?: string;
    };

// eslint-disable-next-line complexity, max-lines-per-function
function DynamicFormikField({
    formik,
    columns,
    className,
    ...addressField
}: DynamicFormikFieldProps): JSX.Element | null {
    const intl = useIntl();
    const shopName = useSiteName();
    const [customerData, setCustomerSessionData] = useCustomerDataFromSession();

    const mergeTags = {
        shopName,
    };

    const handleFieldChange = (value: string | boolean) => {
        formik.setFieldValue(addressField.name, value);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, fieldName: string) => {
        if (fieldName === 'billing_country' && customerData?.billingData) {
            customerData.billingData.values.billing_postalCode = '';
            setCustomerSessionData(customerData);
        }
        formik.handleChange(event);
    };

    const setFieldValueWithTimeout = (name: string, value: string, timeout: number) => {
        setTimeout(() => {
            formik.setFieldValue(name, value);
        }, timeout);
    };

    if (!addressField.type) {
        return null;
    }

    const placeholderId = addressField.placeholderId ?? addressField.labelId;
    const errorMessage =
        formik.touched[addressField.name] && formik.errors[addressField.name]
            ? intl.formatMessage({ id: formik.errors[addressField.name] })
            : undefined;

    let pattern;
    if (addressField.type === 'MaskedInput') {
        pattern = addressField.validations?.find((validation) => validation.type === 'matches')?.params[0];
    }

    return (
        <div
            className={classNames(
                componentName,
                className,
                `${componentName}--${addressField.type}`,
                { [`${componentName}--cols-mobile-${columns?.mobile}`]: columns?.mobile },
                { [`${componentName}--cols-tablet-${columns?.tablet}`]: columns?.tablet },
                { [`${componentName}--cols-desktop-${columns?.desktop}`]: columns?.desktop },
                { [`${componentName}--relative`]: Boolean(addressField.horizontalSeparator) }
            )}
        >
            {addressField.type === 'text' || addressField.type === 'email' || addressField.type === 'date' ? (
                <Input
                    id={addressField.name}
                    name={addressField.name}
                    type={addressField.type}
                    label={addressField.labelId ? intl.formatMessage({ id: addressField.labelId }) : undefined}
                    placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
                    value={formik.values[addressField.name]}
                    error={errorMessage}
                    required={addressField.required}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={addressField.disabled}
                />
            ) : (
                SKIP_RENDER
            )}

            {addressField.type === 'password' ? (
                <PasswordWithShowInput
                    id={addressField.name}
                    name={addressField.name}
                    label={addressField.labelId ? intl.formatMessage({ id: addressField.labelId }) : EMPTY_STRING}
                    placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
                    value={formik.values[addressField.name]}
                    error={errorMessage}
                    required={addressField.required}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                />
            ) : (
                SKIP_RENDER
            )}

            {addressField.type === 'loqate' && (
                <LoqateInput
                    name={addressField.name}
                    label={addressField.labelId ? intl.formatMessage({ id: addressField.labelId }) : EMPTY_STRING}
                    placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
                    error={errorMessage}
                    required={addressField.required}
                    formik={formik}
                    setFieldValueWithTimeout={setFieldValueWithTimeout}
                />
            )}

            {addressField.type === 'MaskedInput' && (
                <MaskedInput
                    id={addressField.name}
                    name={addressField.name}
                    type={addressField.type}
                    label={addressField.labelId ? intl.formatMessage({ id: addressField.labelId }) : EMPTY_STRING}
                    placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
                    value={formik.values[addressField.name]}
                    error={errorMessage}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    mask={addressField.inputMask}
                    required={addressField.required}
                    transform={addressField.transform}
                    maskPlaceholder={addressField.maskPlaceholder}
                    pattern={pattern}
                />
            )}

            {addressField.type === 'radio' && (
                <RadioButtonGroup
                    id={addressField.name}
                    name={addressField.name}
                    labelId={addressField.labelId}
                    value={formik.values[addressField.name]}
                    values={addressField.values}
                    error={errorMessage}
                    onChange={formik.handleChange}
                />
            )}

            {addressField.type === 'select' && (
                <DynamicSelect
                    name={addressField.name}
                    label={addressField.labelId ? intl.formatMessage({ id: addressField.labelId }) : EMPTY_STRING}
                    placeholderId={addressField.placeholderId ?? EMPTY_STRING}
                    value={formik.values[addressField.name]}
                    values={addressField.values}
                    disabled={addressField.disabled}
                    error={errorMessage}
                    required={addressField.required}
                    onChange={(event) => handleSelectChange(event, addressField.name)}
                />
            )}

            {addressField.type === 'checkbox' && (
                <Checkbox
                    id={addressField.name}
                    name={addressField.name}
                    value={Boolean(formik.values[addressField.name])}
                    error={errorMessage}
                    onBlur={formik.handleBlur}
                    onChange={handleFieldChange}
                    required={addressField.required}
                >
                    <FormattedMessage id={addressField.labelId} values={mergeTags} />
                </Checkbox>
            )}

            {addressField.type === 'DateOfBirth' && (
                <DynamicDateOfBirth
                    id={addressField.name}
                    name={addressField.name}
                    labelId={addressField.labelId}
                    defaultValue={formik.values[addressField.name]}
                    error={errorMessage}
                    onChange={(value) => {
                        formik.setFieldValue(addressField.name, value.date);
                        formik.setFieldValue('isBirthdateValid', value.isValid);
                    }}
                    required={addressField.required}
                />
            )}

            {addressField.type === 'tel' && (
                <PhoneNumberInput
                    required={addressField.required}
                    id={addressField.name}
                    name={addressField.name}
                    label={addressField.labelId ? intl.formatMessage({ id: addressField.labelId }) : EMPTY_STRING}
                    error={errorMessage}
                    onBlur={formik.handleBlur}
                    value={formik.values[addressField.name]}
                    onChange={(event) => formik.setFieldValue(addressField.name, event.target.value)}
                />
            )}

            {addressField.type === 'textarea' && (
                <DynamicTextarea
                    id={addressField.name}
                    name={addressField.name}
                    labelId={addressField.labelId}
                    maxLength={addressField.maxLength}
                    placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : undefined}
                    error={errorMessage}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values[addressField.name]}
                />
            )}

            {addressField.horizontalSeparator ? (
                <div className={`${componentName}__separator`}>{addressField.horizontalSeparator}</div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.DynamicFormik.DynamicFormikField', DynamicFormikField);
