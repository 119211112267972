import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { RefObject, useEffect, useState, type MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { ProductSuggestions } from './ProductSuggestion/ProductSuggestions';
import { Product, ProductWithActiveVariant, SearchLandingPage } from '@mediashop/app/api/types/ClientProduct';

const componentName = 'search-suggestion';

const MAX_SUGGESTIONS_DISPLAY = 5;

const getActiveVariant = (products: Array<Product>, searchQuery: string): Array<ProductWithActiveVariant> => {
    return products.map((product) => {
        const foundVariant = product.variants.find((variant) => variant.sku === searchQuery);
        if (foundVariant) {
            return { ...product, activeVariant: foundVariant };
        }

        return product;
    });
};

type Props = {
    keywords: Array<string>;
    suggestedProducts: Array<Product>;
    searchResultsCount: number;
    searchQuery: string;
    goToSearchResultsPage: (searchQuery?: string) => void;
    landingPage?: SearchLandingPage;
    searchResponse: Product[];
    handleProductClick: (event: MouseEvent<HTMLAnchorElement>, product: Product) => void;
    activeSuggestionWidth?: number;
    searchSuggestionRef: RefObject<HTMLDivElement>;
};

// eslint-disable-next-line max-lines-per-function
const SearchSuggestion = ({
    keywords = [],
    suggestedProducts = [],
    searchResultsCount = 0,
    searchQuery,
    goToSearchResultsPage,
    landingPage,
    searchResponse = [],
    handleProductClick,
    activeSuggestionWidth,
    searchSuggestionRef,
}: Props): JSX.Element => {
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();
    useEffect(() => setDevice(deviceType), [deviceType]);
    const isMobileDevice = device === 'mobile';

    const boldTextSuggestion = (suggestion: string) => {
        /* get the word parts of the searchQuery string and create a regular expression to check if the word is existing in the suggestion */
        const words = searchQuery.trim().split(/\s+/);
        const escapedWords = words.map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');
        const parts = suggestion.split(regex).filter(Boolean);

        return (
            <>
                {parts.map((part) => (
                    <span key={part}>
                        {regex.test(part) ? (
                            <span>{part}</span>
                        ) : (
                            <span className={`${componentName}__autosuggest-keyword-bold`}>{part}</span>
                        )}
                    </span>
                ))}
            </>
        );
    };

    const SuggestionWrapper = ({ children }: BasePropsWithChildren): JSX.Element => {
        if (isMobileDevice) {
            return <div className={`${componentName}__result-mobile-wrapper`}>{children}</div>;
        }
        return <>{children}</>;
    };

    const searchResponseWithActiveVariant = getActiveVariant(searchResponse, searchQuery);
    const suggestedProductsWithActiveVariant = getActiveVariant(suggestedProducts, searchQuery);

    return (
        <div
            className={classNames(`${componentName}__autosuggest`, {
                [`${componentName}__autosuggest--has-search-suggestion`]: keywords.length > 0 || landingPage,
            })}
            ref={searchSuggestionRef}
            style={{ maxWidth: activeSuggestionWidth ? `${activeSuggestionWidth}px` : undefined }}
        >
            {searchQuery.length >= 2 ? (
                <>
                    <SuggestionWrapper>
                        {keywords.length > 0 || landingPage ? (
                            <div className={`${componentName}__autosuggest-keywords-wrapper`}>
                                {keywords.length > 0 ? (
                                    <>
                                        <div className={`${componentName}__autosuggest-headline`}>
                                            <FormattedMessage id="mainNavigation.searchSuggestion" />:
                                        </div>
                                        <ul className={`${componentName}__autosuggest-keywords`}>
                                            {keywords?.map((suggestion) => (
                                                <li
                                                    onClick={() => goToSearchResultsPage(suggestion)}
                                                    key={suggestion}
                                                    className={`${componentName}__autosuggest-keyword`}
                                                >
                                                    {boldTextSuggestion(suggestion)}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    SKIP_RENDER
                                )}

                                {landingPage ? (
                                    <>
                                        <div className={`${componentName}__autosuggest-headline`}>
                                            <FormattedMessage id="mainNavigation.content" />
                                        </div>
                                        <div className={`${componentName}__autosuggest-keywords`}>
                                            <p
                                                onClick={() => window.location.replace(landingPage.url)}
                                                className={`${componentName}__autosuggest-keyword`}
                                            >
                                                {landingPage.name}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    SKIP_RENDER
                                )}
                            </div>
                        ) : (
                            SKIP_RENDER
                        )}

                        {searchResultsCount > 0 || suggestedProductsWithActiveVariant.length > 0 ? (
                            <div className={`${componentName}__autosuggest-results-wrapper`}>
                                <div className={`${componentName}__autosuggest-headline`}>
                                    <FormattedMessage id="mainNavigation.productSuggestion" />:
                                </div>
                                <div className={`${componentName}__autosuggest-results`}>
                                    {searchResponseWithActiveVariant.length > 0 ? (
                                        <ProductSuggestions
                                            productSuggestions={searchResponseWithActiveVariant?.slice(
                                                0,
                                                MAX_SUGGESTIONS_DISPLAY
                                            )}
                                            handleProductClick={handleProductClick}
                                        />
                                    ) : (
                                        <ProductSuggestions
                                            productSuggestions={suggestedProductsWithActiveVariant}
                                            handleProductClick={handleProductClick}
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            SKIP_RENDER
                        )}
                    </SuggestionWrapper>
                    {searchResultsCount > 0 ? (
                        <div className={`${componentName}__search-page-button`} onClick={() => goToSearchResultsPage()}>
                            <FormattedMessage
                                id="mainNavigation.showResults"
                                values={{ count: searchResultsCount, text: <span>{searchResultsCount}</span> }}
                            />
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                </>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
};

export default SearchSuggestion;
